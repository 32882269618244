import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';
import { Main } from '@styles';

const StyledBlogContainer = styled(Main)`
`;
const StyledBlogHeader = styled.header`
  margin-bottom: 50px;
  .tag {
    margin-right: 10px;
  }
`;
const StyledBlogContent = styled.div`
  margin-bottom: 100px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 1em;
  }

  p {
    margin: 1em 0;
    line-height: 1.5;
    color: var(--light-slate);
  }
`;

const BlogTemplate = ({ data, location }) => {
    const { frontmatter, html } = data.markdownRemark;
    const { title, date } = frontmatter;

    return (
        <Layout location={location}>
            <Helmet title={title} />

            <StyledBlogContainer>
                <span className="breadcrumb">
                    <span className="arrow">&larr;</span>
                    <Link to="/blog">All posts</Link>
                </span>

                <StyledBlogHeader>
                    <h1 className="medium-heading">{title}</h1>
                    <p className="subtitle">
                        <time>
                            {new Date(date).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </time>
                    </p>
                </StyledBlogHeader>

                <StyledBlogContent dangerouslySetInnerHTML={{ __html: html }} />
            </StyledBlogContainer>
        </Layout>
    );
};

export default BlogTemplate;

BlogTemplate.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
        description
        date
        slug
        tags
      }
    }
  }
`;